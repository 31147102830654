import React from 'react';
import CustomLocalizedLink from "../locales/custom-localized-link";
import {useTranslation} from "gatsby-plugin-react-i18next";

const BrandResourceLink = ({img, label, url}) => {
  const {t} = useTranslation();
  let strLabel = 'DOWNLOAD '+label+' LOGO & IMAGES KIT';
  const handleLinkButton = () => {
    alert('download resource');
  }
  return (
      <div>
        {
          label === 'Digilock'
              ?
              <img src={img} alt={label} width={'300'} height={'144'} className={'digilogo'} />
              :
              <img src={img} alt={label} width={'359'} height={'248'} />
        }
        <CustomLocalizedLink
            goto={url}
            label={strLabel}
            cls={'btn btn--orange'}
            onClick={handleLinkButton}
        />
      </div>
  )
}

export default BrandResourceLink;