import React from 'react';
import CustomLocalizedLink from "../locales/custom-localized-link";
import {useTranslation} from "gatsby-plugin-react-i18next";

const BrandGuidelinesNav = ({selected}) => {
  const {t} = useTranslation();
  const whichSelected = (label) => {
    if(label === selected) {
      return 'btn btn--brand-guidelines selected';
    } else {
      return 'btn btn--brand-guidelines';
    }

  }
  return (
      <nav className={'bg-header'}>
        <CustomLocalizedLink goto={'/brand-guidelines/'} label={t('bg_nav_bg')} cls={whichSelected('guidelines')} />
        <CustomLocalizedLink goto={'/brand-guidelines/brand-resources/'} label={t('bg_nav_resources')} cls={whichSelected('resources')} />
        <CustomLocalizedLink goto={'/brand-guidelines/trademark-usage/'} label={t('bg_nav_trademark')} cls={whichSelected('trademark')} />
      </nav>
  );
}

export default BrandGuidelinesNav;