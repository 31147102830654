import React, {useState, useEffect} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';
import BrandGuidelinesNav from "../../components/brand-guidelines/brand-guidelines-nav";
import {StaticImage} from "gatsby-plugin-image";
import CustomLocalizedLink from "../../components/locales/custom-localized-link";
import BrandResourceLink from "../../components/brand-guidelines/brand-resource-link";
import brandAspire from './images/brand-res-aspire.png';
import brandCleo from './images/brand-res-cleo.png';
import brandCurve from './images/brand-res-curve.png';
import brandOrbit from './images/brand-res-orbit.png';
import brandPivot from './images/brand-res-pivot.png';
import brandVersa from './images/brand-res-versa.png';
import brandDigilock from './images/logo-corporate-reverse.png'


const IndexPage = () => {

  const {t} = useTranslation();

  return (
      <Layout>
        <Seo
            title={t('brand_guidelines_resources_title')}
            description={t('BRAND RESOURCES DESCRIPTION')}
        />
        <div className="brand-guidelines resources">
          <section className={'brand-guidelines-header'}>
            <div className="container">
              <h1 className={'headline'}><Trans>bg_headline</Trans></h1>
              <div className="section-delimeter"></div>
              <BrandGuidelinesNav selected={'resources'}/>
              <h2><Trans>bg_nav_resources</Trans></h2>
            </div>
          </section>
          <section className={'brand-resources'}>
            <div class="container">
              <div className="grid-two-col list last">
                <div>
                  <BrandResourceLink label={'Digilock'} img={brandDigilock} url={'https://cdn.mediavalet.com/usva/digilock/LPlP_ggagkiXg0YGw25iRA/pEt-y4_YRkWTva5KTqD8BA/Original/Digilock_Logos_Kit.zip'} />
                </div>
              </div>
              <div className="grid-two-col">
                  <BrandResourceLink label={'Aspire'} img={brandAspire} url={'https://cdn.mediavalet.com/usva/digilock/VqkV6Jhn_U-Ywt5jEAHY8Q/UPzfIRaYlEKl_DkWJ6jZAg/Original/Digilock_Aspire_Kit.zip'} />
                  <BrandResourceLink label={'Cleo'} img={brandCleo} url={'https://cdn.mediavalet.com/usva/digilock/Sgf66uAf50Otg4U_6epQiw/zIvd-OTzrkqAk-UEQ3X4FA/Original/Digilock_Cleo_Kit.zip'} />
              </div>
              <div className="grid-two-col">
                <BrandResourceLink label={'Curve'} img={brandCurve} url={'https://cdn.mediavalet.com/usva/digilock/X0W9JcmiEUCukORTUJjLjg/49FQ1T9TsUC9-qEDCdWF8w/Original/Digilock_Curve_Kit.zip'} />
                <BrandResourceLink label={'Orbit'} img={brandOrbit} url={'https://cdn.mediavalet.com/usva/digilock/iS2GykkiUEGLxG4rIJChVw/WghqRuL6KkSzazINP5l06g/Original/Digilock_Orbit_Kit.zip'} />
              </div>
              <div className="grid-two-col">
                <BrandResourceLink label={'Pivot'} img={brandPivot} url={'https://cdn.mediavalet.com/usva/digilock/IfHQ9u3nXkSuc7trS8kITQ/GWHB7ttHbkyF9BjijVnCoQ/Original/Digilock_Pivot_Kit.zip'} />
                <BrandResourceLink label={'Versa'} img={brandVersa} url={'https://cdn.mediavalet.com/usva/digilock/n0CJeYFCOU6QuFhB-2k1SQ/SOavKs8bFEqdr0GnNtVV_A/Original/Digilock_Versa_Kit.zip'} />
              </div>
            </div>
          </section>
          <section className="bottom-nav">
            <div className="container">
              <div className="section-delimeter"></div>
              <CustomLocalizedLink label={t('bg_nav_trademark_footer')+' &gt;'} goto={'/brand-guidelines/trademark-usage/'} cls={'bg-footer-nav'} />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
